.language-selector {
    position: relative;
    width: 100px;
    cursor: pointer;
    right: 0;
    margin-left: 50px;
}
.language-selector .dropdown-container svg {
    max-width: 30px;
    max-height: 30px;
    margin-right: 7px;
}
.language-selector .options-container {
    position: absolute;
    border: 1px solid #d9d9d9;
    background: white;
    padding: 5px;
    width: 120px;

}
.options-container .option:hover {
    background: #b5b5b5;
}

.dropdown-option-icon {
    float: left;
}
.dropdown-option-label {
    float: left;
}
.language-selector .selected-option {
    float: right;
    position: relative;
    height: 44px;
    line-height: 30px;
    width: 110px;
    border: 1px solid #d9d9d9;
    padding: 5px;
    left: 10px;
}



@media only screen and (max-width: 740px) {
    .language-selector {
        margin-top: 10px;
        margin-left: 0px;
    }
}

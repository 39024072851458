body {
  background-color: #f2f6fc !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.9rem !important;
  color: #2b2b2b !important;
  line-height: 1.5 !important;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
.bg-light {
  background-color: #ffffff !important;
}

button,
a,
link,
input,
select,
textarea {
  border-radius: 0px !important;
}

.table > :not(:first-child) {
  border-top: 0 !important;
}

.py-1.footer {
  position: fixed;
  bottom: 0;
  font-size:12px;
}

.footer-copyrights {
  float: left;
  width: auto;
  color:black;
  padding: 5px;
}

.footer-developed-by {
   float: right;
   width: auto;
   font-size: 11px;
  color:black;
  padding: 5px;
}

h3.home-animation-h3 {
  font-family: "Montserrat Medium";
  max-width: 40ch;
  text-align: center;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

span.home-animation-span {
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
  margin: 0px 5px 0px 5px;
}

span.home-animation-span:nth-child(1) {
  animation: fade-in 2s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span.home-animation-span:nth-child(2) {
  animation: fade-in 2s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

/*span.home-animation-span:nth-child(3) {*/
/*  animation: fade-in 2s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);*/
/*}*/

/*span.home-animation-span:nth-child(4) {*/
/*  animation: fade-in 2s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);*/
/*}*/

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.edit-grid-view .empty-values {
  background: #f6ffd6;
}

.Toastify__toast-container--top-right {
  top: 25% !important;
  right: 0 !important;
  left: 0 !important;
  margin: auto;
}

.form-group-title {
  border-bottom: 1px solid rgb(128, 128, 128);
  padding: 5px 0px;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.admin-list-title-icon {
  max-width: 40px;
  padding-bottom: 8px;
  opacity: 0.5;
}

.list-item-thumbnail img {
  max-width: 120px;
}

.table-sm tbody tr td {
  vertical-align: middle;
}
td.outdated-connection{
  background: #ffffab;
}
.border.border-secondary.p-2 {
  float: left;
}
.roleLabel {
  font-weight: 700;
  padding: 10px 0px;
  font-size: 20px;
}
.file-input-container {
  margin: 10px 0px;
  margin-bottom: 10px;
  margin-bottom: 10px;
  float: left;
  width: 100%;
  border: 1px solid #d7d7d7;
  padding: 20px;
}
.file-input-upload {
  float: left;
  width: 50%;
  clear: left;
}
.images-container-input {
  float: left;
  width: 100%;
  clear: right;
  text-align: center;
  margin: 20px 0px;
  display: flex;
  align-items: center;
  border: 2px solid white;
}
.images-container-input[data-operation="remove"] {
  border: 2px solid red;
}
.thumbnail-input-img {
  width: 25%;
  float: left;
  height: 150px;
  max-height: 150px;
  padding-left: 30px;
}
.thumbnail-input-img img {
  height: 100%;
}

.thumbnail-input-remove {
  float: right;
  background: none;
  border: none;
  font-weight: 700;
  color: red;
  padding: 20px;
  width: 25%;
  font-size: 16px;
}
.thumbnail-input-remove:hover {
  color: black;
  font-size: 20px;
}
.images-container-input[data-operation="remove"] .thumbnail-input-remove {
  font-size: 40px;
}
.thumbnail-container-input {
  width: 50%;
  text-align: center;
  display: inline;
  padding: 0px 100px;
}
.thumbnail-file-input-add {
  float: left;
  background: #e1e1e1;
  font-weight: 700;
  color: black;
  padding: 10px;
  width: 100%;
  border: 0px;
  margin: 0px 0px 30px;
}
.order-td[data-status="COMPLETED"] {
  color: green;
  font-weight: 700;
}
td.category-list-thumbnail img{
  max-width: 100px;
}
td.product-list-thumbnail img{
  max-width: 100px;
}
td.brand-list-thumbnail img{
  max-width: 100px;
}
.dropdown-item.logout-menu-item {
  color: #240101;
  background: #ececec;
  font-weight: 700;
}
.btn-close-popup-admin {
  background: unset;
  border: unset;
  color: #5e5e5e;
  font-size:17px;
}
.message-master {
  width: 100%;
  left: 0;
}

.message-master .toast.show {
  margin: auto;
}
.message-admin-master {
  background: #bdbdbd75;
}
.admin-toast{
  padding:30px;
}
.total-stock-machine {
  font-weight: 500;
  color: red;
  cursor: pointer;
}
.fetch-total-stock {
  font-weight: 500;
  cursor: progress;
}
.total-price-machine {
  font-weight: 500;
  color: red;
  cursor: pointer;
}
.fetch-total-price {
  font-weight: 500;
  cursor: progress;
}
.refetch-icon {
  max-width: 30px;
}
.modal-dialog{
  max-width: 1300px;
}
.modal-dialog .modal-body .mb-3{
  float: left;
  width: 100%;
}
.main-user-error-message-block {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.not-found-page-container {
  width: 100%;
  text-align: center;
  margin-top: 100px;
}
.table-sm > :not(caption) > * > *{
  text-align: center  ;
}
#machineModal .modal-dialog {
  max-width: 1600px;
}

@media only screen and (max-width: 740px) {

}